
import { Component, Vue } from "vue-property-decorator";
import TentForm from "@/views/GSSApp/Tent/TentForm.vue";
import { mixins } from "vue-class-component";
import CreateMixin from "@/mixins/Crud/CreateMixin";
import { Tent } from "@/config/Modules";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { TentStoreMixin } from "@/mixins/Stores/TentStoreMixin";

@Component({
  components: { TentForm },
})
export default class TentCreate extends mixins(CreateMixin, TentStoreMixin) {
  get resource(): string {
    return Tent.resource;
  }

  get descriptionField(): string {
    return Tent.description_field;
  }

  public async createItemAction(options: ICrudOptions): Promise<any> {
    return this.createTentItemAction(options);
  }

  public redirectAfterCreateSuccess(responseData: any) {
    if (responseData.id) {
      this.$router.push({ name: "TentShow", params: { id: responseData.id } });
      return;
    }
    this.$router.push({ name: "TentList" });
  }
}
